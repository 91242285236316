import request from '@/utils/request'


// 查询签到配置列表
export function listSignInRule(query) {
  return request({
    url: '/biz/signInRule/list',
    method: 'get',
    params: query
  })
}

// 查询签到配置分页
export function pageSignInRule(query) {
  return request({
    url: '/biz/signInRule/page',
    method: 'get',
    params: query
  })
}

// 查询签到配置详细
export function getSignInRule(data) {
  return request({
    url: '/biz/signInRule/detail',
    method: 'get',
    params: data
  })
}

// 新增签到配置
export function addSignInRule(data) {
  return request({
    url: '/biz/signInRule/add',
    method: 'post',
    data: data
  })
}

// 修改签到配置
export function updateSignInRule(data) {
  return request({
    url: '/biz/signInRule/edit',
    method: 'post',
    data: data
  })
}

// 删除签到配置
export function delSignInRule(data) {
  return request({
    url: '/biz/signInRule/delete',
    method: 'post',
    data: data
  })
}

export function porpsList(data) {
  return request({
    url: '/biz/propsInfo/list',
    method: 'get',
    params: data
  })
}