<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="赠送类型" prop="signType" >
        <a-select placeholder="请选择类型" v-model="form.signType" @change="changeType">
          <a-select-option v-for="(d, index) in signTypeOptions" :key="index" :value="d.value"  >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="道具" prop="toolsId" v-if="signTypeChange==='2'">
        <a-select placeholder="请选择类型" v-model="form.toolsId">
          <a-select-option v-for="(d, index) in porpsList" :key="index" :value="d.id"  >{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="获得钻石数量" prop="gold" v-if="signTypeChange==='1'" >
        <a-input v-model="form.gold" placeholder="请输入签到获得钻石数量" />
      </a-form-model-item>
      <a-form-model-item label="签到天数" prop="dayTitle" >
        <a-input v-model="form.dayTitle" placeholder="请输入签到天数" />
      </a-form-model-item>
<!--      <a-form-model-item label="宝箱最小钻石" prop="minGold" >-->
<!--        <a-input v-model="form.minGold" placeholder="请输入宝箱最小钻石" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="宝箱最大钻石" prop="maxGold" >-->
<!--        <a-input v-model="form.maxGold" placeholder="请输入宝箱最大钻石" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="是否是会员" prop="vipFlag" >
        <a-radio-group v-model="form.vipFlag" button-style="solid">
          <a-radio-button v-for="(d, index) in vipFlagOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input-number v-model="form.sort" placeholder="请输入排序" :min="1" :max="9999999999" :step="1" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSignInRule, addSignInRule, updateSignInRule ,porpsList} from '@/api/biz/signInRule'

export default {
  name: 'CreateForm',
  props: {
    signTypeOptions: {
      type: Array,
      required: true
    },
    vipFlagOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      porpsList:[], //道具下拉数据
      signTypeChange:1,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        signType: null,

        gold: null,

        dayTitle: null,

        minGold: null,

        maxGold: null,

        vipFlag: 0,

        sort: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
    this.getPorpsList()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeType(val) {
      console.log(val)
      this.signTypeChange=val;
    },
    getPorpsList(){
      porpsList({"status":1,"accessType":4 }).then(response => {
        this.porpsList=response.data;
      });
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        signType: null,
        gold: null,
        dayTitle: null,
        minGold: null,
        maxGold: null,
        vipFlag: 0,
        sort: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSignInRule({"id":id}).then(response => {
        let resultData=response.data;
       this.signTypeChange=resultData.signType+"";
        resultData.signType=resultData.signType+'';
        this.form = resultData
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            let dataParam=this.form;
            updateSignInRule(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSignInRule(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
